export default [
  {
    header: 'Fatura',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Giden Faturalar',
        route: 'send-invoice',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
