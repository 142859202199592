export default [
  {
    header: 'Ürün',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Ürünler',
        route: 'product-product',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Kategori',
        route: 'product-category',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
