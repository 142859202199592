export default [
  {
    header: 'Sipariş',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Sipariş',
        route: 'order-order',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
