import home from '@/navigation/horizontal/home'
import product from '@/navigation/horizontal/product'
import trendyol from '@/navigation/horizontal/trendyol'
import order from '@/navigation/horizontal/order'
import invoice from '@/navigation/horizontal/invoice'

export default [
  ...home,
  ...product,
  ...trendyol,
  ...order,
  ...invoice,
]
